import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNonNulli } from 'flyid-core/dist/Util/helpers';
import { WritableDraft } from 'immer';
import {
  FirestoreState,
  ModifiableTarget,
  RemoveDataKeys,
  SetAuthProviderData,
  SetDataError,
  SetDataLoaded,
  SetDataLoading
} from './../types/firestoreTypes';

export type SetDataLoadedActionType = <Target extends ModifiableTarget>(
  state: WritableDraft<FirestoreState>,
  action: PayloadAction<SetDataLoaded<Target>>
) => void;

const name = 'firestore' as const;
const SetDataLoadedAction = `${name}/setDataLoaded` as const;

const firestoreSlice = createSlice({
  name,
  initialState: new FirestoreState(),
  reducers: {
    // Authentication provider data
    setAuthProvider: (state, action: PayloadAction<SetAuthProviderData>) => {
      state.authProviders = Object.assign(state.authProviders ?? {}, {
        areAuthProvidersLoaded: true,
        providers: action.payload
      });
    },
    // Moderator and KeyUser data
    setDataLoading: (state, action: PayloadAction<SetDataLoading>) => {
      const { company, target, queryCount } = action.payload;

      state[target][company] = {
        isLoaded: queryCount ? Array(queryCount).map((_) => false) : false
      };
    },
    setDataError: (state, action: PayloadAction<SetDataError>) => {
      state[action.payload.target][action.payload.company] = {
        isLoaded: true,
        error: action.payload.error
      };
    },
    removeData: (state, action: PayloadAction<RemoveDataKeys>) => {
      const { target, company, keys } = action.payload;
      if (keys) {
        keys.forEach((keyToRemove) => {
          company
            ? delete state[target][company]?.[keyToRemove]
            : delete state[target]?.[keyToRemove];
        });
      } else state[target] = {};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      SetDataLoadedAction,
      <Target extends ModifiableTarget>(
        state: WritableDraft<FirestoreState>,
        // Assumed redux toolkit action typing is broken, since it's barely impossible to make it
        // work properly with generics
        untypedAction: any
      ) => {
        // Type it properly and follow on.
        const action = untypedAction as PayloadAction<SetDataLoaded<Target>>;
        const { company, target, queryIndex, data } = action.payload;

        let tgtState = state[target][company] as FirestoreState[Target][string];
        // Do not overwrite error
        if (tgtState?.error) return;

        // Prepare isLoaded
        let isLoaded: boolean | boolean[] = true;
        if (isNonNulli(queryIndex)) {
          isLoaded = [...(tgtState!.isLoaded as boolean[])];
          isLoaded[queryIndex] = true;
        }
        // if (queryIndex) logSnapshot(isLoaded);

        // Set data
        if (tgtState) {
          Object.assign(tgtState, { isLoaded, error: undefined });
          if (typeof data === 'object') {
            if (dataIsArray(data)) {
              const mergedData = [...data, ...((tgtState.data as string[]) ?? [])];
              tgtState.data = [...new Set(mergedData)];
            } else {
              tgtState.data = Object.assign(tgtState.data ?? {}, data);
            }
          }
        } else {
          tgtState = {
            isLoaded,
            error: undefined,
            data
          } as unknown as FirestoreState[Target][string];
        }
      }
    );
  }
});

const dataIsArray = (inData: any): inData is any[] => Array.isArray(inData);

// Should only be called after setDataLoading. It expects the loading structure to be present.
// This action isc reated manually because
export const setDataLoaded = <Target extends ModifiableTarget>(payload: SetDataLoaded<Target>) => ({
  type: SetDataLoadedAction,
  payload
});

export const { setAuthProvider, setDataLoading, setDataError, removeData } = firestoreSlice.actions;

export default firestoreSlice.reducer;
