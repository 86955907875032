import { Company } from 'flyid-core/dist/Database/Models';
import type { APIKey } from 'flyid-core/dist/Database/Models/APIKey';
import type { AuthenticationProvider } from 'flyid-core/dist/Database/Models/CompanySettings';
import type { License } from 'flyid-core/dist/Database/Models/License';
import type { DomainSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import type { UserPublic } from 'flyid-core/dist/Database/Models/User';
import { immerable } from 'immer';
import type { Nilable, Undefinable } from 'tsdef';

export type SetAuthProviderData = Undefinable<{ [company: string]: AuthenticationProvider }>;

export type SetUserProfilesData = {
  company: string;
  userProfiles: { [uid: string]: UserPublic | undefined };
};

export const selectFirestoreTarget = <T extends ModifiableTarget>(type: T): T => type;

export type ModifiableState = Omit<FirestoreState, 'authProviders' | typeof immerable>;
export type ModifiableTarget = keyof ModifiableState;
export type SetDataLoading = {
  target: ModifiableTarget;
  queryCount?: number;
  company: string;
};

export type SetDataLoaded<Target extends ModifiableTarget> = {
  target: Target;
  queryIndex?: number;
  company: string;
  data: ExtractAsyncData<FirestoreState[Target][string]>;
};

export type SetDataError = {
  target: ModifiableTarget;
  company: string;
  queryIndex?: number;
  error: string;
};

export type RemoveDataKeys = {
  target: ModifiableTarget;
  company?: string;
  /** Set to null to remove all entries */
  keys: Nilable<string[]>;
};

type CompanyLoadable<T> = { [company: string]: AsyncData<T> | undefined };

export class FirestoreState {
  [immerable] = true;

  authProviders: {
    areAuthProvidersLoaded: boolean;
    providers?: { [company: string]: AuthenticationProvider };
  } = { areAuthProvidersLoaded: false };
  domainSettings: CompanyLoadable<{ [domain: string]: DomainSettings | undefined }> = {};
  userProfiles: CompanyLoadable<{ [uid: string]: UserPublic | undefined }> = {};
  authLicenses: CompanyLoadable<{ [license: string]: License | undefined }> = {};
  apiKeys: CompanyLoadable<{ [apiKey: string]: APIKey | undefined }> = {};
  companiesData: CompanyLoadable<Company> = {};
  authDomains: CompanyLoadable<string[]> = {};
}
