import { TableColumns, TableRows } from './table';

type OrderType = 'asc' | 'desc';
type ComparatorReturn = -1 | 0 | 1;
type ObjectComparator = <T extends object>(a: T, b: T) => ComparatorReturn;

/**
 * Returns sorting result from comparison of two objects (a and b) using its 'orderBy' key
 */
function desc<T extends object>(a: T, b: T, orderBy: string): ComparatorReturn {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Functions that returns sorting result depending on order (desc or asc)
 */
function getSorting(order: OrderType, orderBy: string): ObjectComparator {
  return (
    order === 'desc'
      ? (a: object, b: object) => desc(a, b, orderBy)
      : (a: object, b: object) => -desc(a, b, orderBy)
  ) as ObjectComparator;
}

/**
 * Sort based on cmp function, if items are equal, sort by initial index
 */
function stableSortFromComparator<T extends object>(
  array: T[],
  cmp: (a: T, b: T) => ComparatorReturn
) {
  const stabilizedThis: [T, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * Filters rows whenever searchText matches any of its columns
 */
export function searchData(rows: TableRows, columns: TableColumns, searchText: string) {
  if (searchText) {
    const upperSearch = searchText.toUpperCase();
    return rows.filter((row) =>
      columns
        .filter((column) => {
          return column.searchable === undefined ? !column.hidden : column.searchable;
        })
        .some((column) => {
          if (column.customFilterAndSearch) {
            return column.customFilterAndSearch(searchText, row, column);
          } else if (column.id) {
            const value = column.dataContentGetter?.(row) ?? row[column.id];

            return value ? value.toString().toUpperCase().includes(upperSearch) : null;
          }
          return null;
        })
    );
  }
  return rows;
}

export const stableSort = <T extends object>(data: T[], order: OrderType, orderBy: string) =>
  stableSortFromComparator(data, getSorting(order, orderBy));
