import { SvgIcon, SvgIconProps, TableCellProps } from '@mui/material';
import {
  AcquisitionData,
  AcquisitionDataFlags,
  AcquisitionDataFlagsV1,
  InflatedAddressesData
} from 'flyid-core/dist/Database/Models/AcquisitionData';
import { Session } from 'flyid-core/dist/Database/Models/Session';
import { DomainSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import { BarcodePattern } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/BarcodePattern';
import { ManualInputField } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import {
  AddressesMapKey,
  TableData
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/TabularData';
import { addressMatchesPattern } from 'flyid-core/dist/Util/acquisition';
import { getBarcodePatternFields, getManualInputFields } from 'flyid-core/dist/Util/database';
import { getFirst, isNulli, sortObjectByKeys } from 'flyid-core/dist/Util/helpers';
import { MapOf } from 'flyid-core/dist/Util/types';
import { isEmpty, isObject, isString } from 'lodash';
import { Nilable } from 'tsdef';
import { TranslatableError } from '../locale';
import { DecodedFile, getInflated, parseTableInputFile } from './files';
import { CustomMarker } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/CustomMarker';

const dateFields = ['checkedAt', 'startTime', 'endTime'];

export const STANDARD_FLAGS_COLUMN = 'standard_flags';
export const PICTURES_COLUMN = 'pictures_column';

// Excel tab name doesn't support any of the following characters
export const excelTabInvalidChars = /[:/\\*?[\]]/g;

type SvgIconType = typeof SvgIcon;

export type Order = 'asc' | 'desc';
export type TableRow = {
  [field: string]: string | number | boolean | JSX.Element | Date;
};
export type TableRows = TableRow[];
export type TableColumns<RowType = TableRow> = HeaderCellObject<RowType>[];
export type HeaderCellObject<RowType = TableRow> = {
  /** The column id, which relates to row data */
  id: string;
  /** Whether the data to be shown on this column is a Date */
  isDate?: boolean;
  /** Whether to disable or not the  */
  disablePadding?: boolean;
  /** The value to be shown on the column header, expected to be user-readable */
  label?: string;
  /** Whether the search should include data from this column */
  searchable?: boolean;
  /** Whether to hide this column (Filter implementation is pending) */
  hidden?: boolean;
  /** Whether this data should be exported when an export is triggered */
  export?: boolean;
  /** The text tooltip to be shown when the user hovers this column's header */
  tooltip?: string;
  /** Fill this data if this header should be shown as an icon instead of a text */
  icon?: {
    /** The actual icon to be shown on the headers */
    iconElement: SvgIconType;
    /** These properties will be passed down to the given icon */
    props?: SvgIconProps;
  };
  /** This propos will be passed down to the header cell */
  headerCellProps?: TableCellProps;
  /** This propos will be passed down to every data cell */
  dataCellProps?: TableCellProps;
  /** Whether a custom search method should be applied for data in this column */
  customFilterAndSearch?: (searchText: string, row: RowType, column: HeaderCellObject) => boolean;
  /** The content getter for this column, if not provided, row data corresponding to this column id is rendered as text. */
  dataContentGetter?: (row: RowType) => JSX.Element | string | null;
};

/**
 * @param {*} dataFields Header fields object in the format {[fieldName]: fieldLabel}
 */
export function getHeaderCellsFromObject<T = TableRow>(
  dataFields: { [name: string]: string },
  doNotExport?: string[]
) {
  if (isEmpty(dataFields)) {
    return [];
  }

  const headerCells: HeaderCellObject<T>[] = [];
  Object.keys(dataFields).forEach((field, idx) => {
    headerCells.push({
      id: field,
      export: doNotExport?.includes(field) ?? true,
      isDate: dateFields.indexOf(field) > -1,
      label: dataFields[field]
    });
  });

  return headerCells;
}

export class GetSessionDataOptions {
  /** Task result only: returns input arguments used during task creation  as well*/
  showInputData = false;
  /** Show data from {@link TypedBaseFields} */
  showBaseFields = false;
}
export type GetSessionDataResult = {
  rows: TableRows;
  columns: TableColumns;
};
/**
 * This method returns a session data in table format ({@link GetSessionDataResult})
 * It should be used to acquire Session data for visualization or export.
 *
 * @param sessionData Session data {@link Session}
 * @param settings {@link DomainSettings} of domain to which this Session belongs
 * @param options {@link GetSessionDataOptions}
 * @returns session data in table format ({@link GetSessionDataResult})
 */
export async function getSessionData(
  sessionData: Session,
  settings: DomainSettings,
  options: Partial<GetSessionDataOptions>
): Promise<GetSessionDataResult> {
  let columns: HeaderCellObject[] = [];
  const dataFields = {};
  // This is the correct type, getSessionData must have acquisiton data WITH address!
  const rowsToReturn = new Map<string, AcquisitionData>();

  const { showInputData, showBaseFields } = Object.assign(new GetSessionDataOptions(), options);
  const addresses = sessionData.addresses;

  const barcodePatterns = Object.values(settings.processFlow.labelDesigns).flatMap(
    (label) => label.barcodePatterns
  );

  const baseFieldsWithoutAddress = removeAddress(settings.fieldSettings.baseFields);

  const addressColumn = settings.fieldSettings.baseFields.address;

  // If showInputData is selected, show results in the exact order as input data, otherwise
  // just add address column
  if (showInputData && sessionData.orderedInputColumns?.length)
    addFields(dataFields, sessionData.orderedInputColumns, addressColumn);
  else addFields(dataFields, { address: addressColumn });
  // Add markers, code patterns and mif in between address and other base fields
  addCustomMarkerFields(dataFields, settings.processFlow.customMarkers);
  addBarcodePatternFields(dataFields, barcodePatterns);
  addManualInputFields(dataFields, settings.processFlow.manualInputFields);
  // If showBaseFields is true, then add checktime and user related basefields
  if (showBaseFields) addFields(dataFields, baseFieldsWithoutAddress);

  let decodedAddresses: InflatedAddressesData = {};
  if (isString(addresses)) {
    try {
      // Handle deflated data
      decodedAddresses = JSON.parse(await getInflated(addresses)) as InflatedAddressesData;
    } catch (e) {
      // Handle non-deflated data
      decodedAddresses = JSON.parse(addresses) as InflatedAddressesData;
    }
  } // handle non deflated collection-document based data
  else if (isObject(addresses)) {
    decodedAddresses = addresses;
  }

  // Columns must contain address
  columns = !isEmpty(dataFields) ? getHeaderCellsFromObject(dataFields) : [];

  for (const address in decodedAddresses) {
    let decodedAddressData = decodedAddresses[address];
    // Handle data before multiple codes per address
    if (!Array.isArray(decodedAddressData)) {
      decodedAddressData = [decodedAddressData];
    }

    for (const newAddressData of decodedAddressData) {
      const outputAddressData: AcquisitionData = { ...newAddressData, address };
      rowsToReturn.set(String(rowsToReturn.size), outputAddressData);
    }
  }

  // Replace undefined and null values with null result
  const nullResult = settings.fieldSettings.resultFields.null || '';
  const rows = Array.from(rowsToReturn.values(), (row) => {
    columns.forEach((c) => {
      if (isNulli(row[c.id])) row[c.id] = nullResult;
    });
    return row as TableRow;
  });

  return { rows, columns };
}

export type ParsedTableData = {
  tableData: Omit<TableData, 'updatedAt'>;
  /** Map of key to possible values */
  data: { [keyColumn: string]: { [valueColumn: string]: string } };
};
/**
 * TODO
 *
 * @param {*} inputFileData decoded file from FileReader
 * @param {*} settings domain settings
 *
 * @throws {TranslatableError}
 */
export async function parseTabularDataInputFile(
  tableName: Nilable<string>,
  inputFileData: DecodedFile,
  settings: DomainSettings
): Promise<ParsedTableData> {
  const parsedTable = await parseTableInputFile(inputFileData);

  // Check single sheet in the file
  if (Object.keys(parsedTable).length !== 1) {
    throw new TranslatableError('todo must have exactly one sheet');
  }
  const { sheetData, orderedColumns } = getFirst(parsedTable)!;
  const isAddressMap = tableName === AddressesMapKey;

  const columnCount = orderedColumns.length;

  // Check if # of columns are equal to or bigger than 2 for data table or
  if (columnCount < 2) {
    throw new TranslatableError('todo');
  } // equal to 2 for address map table
  else if (isAddressMap && columnCount !== 2) {
    throw new TranslatableError('todo');
  }

  const keyColumn = orderedColumns[0];
  const valueColumns = orderedColumns.slice(1);

  const data: { [keyColumn: string]: { [valueColumn: string]: string } } = {};
  sheetData.forEach((record, index) => {
    // If Address Map, check if column matches addressing pattern
    if (isAddressMap) {
      if (!addressMatchesPattern(settings.fieldSettings.addrPattern, record[valueColumns[0]])) {
        throw new TranslatableError(
          `todo value (${
            record[valueColumns[0]]
          }) in row (${index}) doesn't match addressing pattern!`
        );
      }
    }
    // Fill return data
    const key = record[keyColumn];
    if (data[key]) {
      throw new TranslatableError(
        `todo Found repeated key at line ${index} (key). Key values must be unique!`
      );
    }
    data[key] = {};
    valueColumns.forEach((valueCol) => (data[key][valueCol] = record[valueCol]));
  });

  return { tableData: { keyColumn, valueColumns }, data };
}

export function addFields(
  dataFields: { [key: string]: string },
  fieldsList: Array<string> | { [field: string]: string },
  addressResult?: string
) {
  if (!isEmpty(fieldsList)) {
    if (Array.isArray(fieldsList)) {
      // List of fields to add, no translation/corresponding value
      fieldsList.forEach((field) => {
        if (!dataFields.hasOwnProperty(field)) {
          if (field !== addressResult) dataFields[field] = field;
          // If field is an address result, make correct replacement
          else dataFields.address = addressResult;
        }
      });
    } else {
      // Sorted keys and corresponding value
      Object.entries(sortObjectByKeys(fieldsList)).forEach(([field, value]) => {
        if (!dataFields.hasOwnProperty(field)) {
          dataFields[field] = value;
        }
      });
    }
  }
}

export function addManualInputFields(
  dataFields: object,
  manualInputFields: MapOf<ManualInputField>
) {
  if (!isEmpty(manualInputFields)) {
    Object.values(manualInputFields).forEach((mif) => {
      if (!dataFields.hasOwnProperty(mif.field)) {
        dataFields[mif.field] = mif.field;
      }
    });
  }
}

export function addCustomMarkerFields(dataFields: object, customMarkers: MapOf<CustomMarker>) {
  if (!isEmpty(customMarkers)) {
    Object.values(customMarkers).forEach((cm) => {
      if (!dataFields.hasOwnProperty(cm.name)) {
        dataFields[cm.name] = cm.name;
      }
    });
  }
}

export function addBarcodePatternFields(dataFields: object, barcodePatterns: BarcodePattern[]) {
  if (!isEmpty(barcodePatterns)) {
    barcodePatterns.forEach((barcodePattern) => {
      if (!isEmpty(barcodePattern.dataFields)) {
        barcodePattern.dataFields.forEach((field) => {
          if (field.type === 'data' && !dataFields.hasOwnProperty(field.name)) {
            dataFields[field.name] = field.name;
          }
        });
      }
    });
  }
}

function removeAddress<T extends { address: string }>(target: T) {
  const res: Omit<T, 'address'> = { ...target };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  delete (res as any).address;
  return res;
}
