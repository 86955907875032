"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterArray = void 0;
/**
 * This method will filter array data based on the given predicate and returns
 * two arrays: for predicate returning true, and predicate returning false.
 * This is useful when both predicate responses are valuable.
 */
const filterArray = (arr, predicate) => {
    const falsePredicates = [];
    const truePredicates = arr.filter((v) => {
        if (predicate(v))
            return true;
        falsePredicates.push(v);
        return false;
    });
    return [truePredicates, falsePredicates];
};
exports.filterArray = filterArray;
