import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FieldPath,
  getDocs,
  query,
  Query,
  QuerySnapshot,
  where
} from 'firebase/firestore';
import { FirestoreReferenceData } from 'flyid-core/dist/Util/database';
import { ArrayWhereFilterOp } from 'flyid-core/dist/Util/firestore';
import { MaybeLoaded } from 'src/redux/selectors/types';
import { firestore } from './firebase';

export function buildDocumentRef<T = DocumentData>(
  ref: FirestoreReferenceData<T>
): DocumentReference<T> {
  const _ref = doc(firestore, ref.path);
  return ref.converter ? _ref.withConverter<T>(ref.converter) : (_ref as DocumentReference<T>);
}

export function buildCollectionRef<T = DocumentData>(
  ref: FirestoreReferenceData<T>
): CollectionReference<T> {
  const _ref = collection(firestore, ref.path);
  return ref.converter ? _ref.withConverter<T>(ref.converter) : (_ref as CollectionReference<T>);
}

export function querySnapToMap<T>(querySnap?: QuerySnapshot<T>): MaybeLoaded<{ [id: string]: T }> {
  return querySnap?.docs?.reduce((obj, docSnap) => {
    obj[docSnap.id] = docSnap.data();
    return obj;
  }, {});
}

export function docsToMap<T>(docs: DocumentSnapshot<T>[]): { [id: string]: T } {
  return docs.reduce((obj, docSnap) => {
    obj[docSnap.id] = docSnap.data();
    return obj;
  }, {});
}

export function getDividedQueries<T>(
  inQuery: CollectionReference<T> | Query<T>,
  compValue: string | FieldPath,
  whereOp: ArrayWhereFilterOp,
  arr: Array<string | FieldPath>
) {
  const divisions = Math.floor(arr.length / 10 + (arr.length % 10 > 0 ? 1 : 0));
  const queries: Array<Query<T>> = [];
  for (let i = 0; i < divisions; i++) {
    const _query = query(inQuery, where(compValue, whereOp, arr.slice(i * 10, (i + 1) * 10)));
    queries.push(_query);
  }
  return queries;
}

export async function getAllFromDividedQuery<T>(queries: Array<Query<T>>) {
  return Promise.all(queries.map((query) => getDocs(query))).then((querySnapList) =>
    querySnapList.flatMap((querySnap) => querySnap.docs)
  );
}

/**
 * This method fetches the given query using a divided query, if necessary.
 *
 * PS.: Divided queries are useful when an array with more than 10 elements is used
 * as parameter on a *where* query.
 */
export async function getQuery<T>(
  inQuery: CollectionReference<T> | Query<T>,
  compValue: string | FieldPath,
  whereOp: ArrayWhereFilterOp,
  arr: Array<string | FieldPath>
) {
  return arr.length > 10
    ? getAllFromDividedQuery(getDividedQueries(inQuery, compValue, whereOp, arr))
    : getDocs(query(inQuery, where(compValue, whereOp, arr))).then((qs) => qs.docs);
}
