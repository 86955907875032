import {
  Checkbox,
  IconButton,
  lighten,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import ConditionalWrapper from 'flyid-ui-components/dist/utils/ConditionalWrapper';
import React, { ChangeEvent, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { appMakeStyles } from 'src/theme/theme';
import { Order, TableColumns } from 'src/util/helpers/table';

const useStyles = appMakeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  header: {
    backgroundColor: lighten(theme.palette.primary.dark, 0.85)
  },
  headerText: {
    fontWeight: 'bold'
  }
}));

export type Props = {
  selectedCount: number;
  columns: TableColumns;
  rowsCount: number;
  onRequestSort: (property: string) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string | undefined;
};

const EnhancedTableHead: React.FC<Props> = (props) => {
  const classes = useStyles(useStyles);
  const { $t } = useIntl();

  const { order, orderBy, selectedCount, rowsCount } = props;

  return (
    <TableHead>
      <TableRow className={classes.header}>
        <Tooltip title={$t({ id: 'eth.selectAll' })}>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={selectedCount > 0 && selectedCount < rowsCount}
              checked={selectedCount === rowsCount}
              onChange={props.onSelectAllClick}
              inputProps={{ 'aria-label': 'select all rows' }}
            />
          </TableCell>
        </Tooltip>
        {props.columns.map((headCell) => {
          const iconCell = headCell.icon;
          const HeaderIcon = iconCell?.iconElement!;
          return (
            <ConditionalWrapper
              key={Math.random()}
              condition={!!headCell.tooltip}
              wrapper={(children) => (
                <Tooltip title={headCell.tooltip!} key={Math.random()}>
                  {children as ReactElement}
                </Tooltip>
              )}
            >
              <TableCell
                key={Math.random()}
                align="right"
                padding="normal"
                sortDirection={orderBy === headCell.id ? order : false}
                {...headCell.headerCellProps}
              >
                {/* Icons are not sortable */}
                {!iconCell ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={() => props.onRequestSort(headCell.id)}
                    className={classes.headerText}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <IconButton aria-label={headCell.id} sx={{ mx: 1, p: 0 }} size={'large'}>
                    {<HeaderIcon fontSize="large" {...iconCell.props} />}
                  </IconButton>
                )}
              </TableCell>
            </ConditionalWrapper>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
