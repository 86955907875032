import { isAutoFillDataComplete } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/AutoFillData';
import { isCaseDataComplete } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import { isCustomMarkerComplete } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/CustomMarker';
import {
  ManualActionNodeTypes,
  NodeType
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import {
  isLabelDesignComplete,
  LabelDesign
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import { isLogicalBlockComplete } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalBlock';
import { isMifComplete } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import { isPictureTakingComplete } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/PictureTaking';
import { MessageType } from 'flyid-core/dist/Redux/types/uiTypes';
import { MapOf } from 'flyid-core/dist/Util/types';
import { IntlShape } from 'react-intl';
import { Connection, Node } from 'reactflow';
import { Nilable, Undefinable } from 'tsdef';
import { Elements, getPureId, getType, isHandleConnected } from './common';
import {
  AppReactFlowInstance,
  BaseNodeData,
  CommonNodeData,
  FlowNodeFront,
  ProcessNodeType,
  SpecificDataTypesListable,
  TypedNode
} from './types';
import { cloneDeep } from 'lodash';
import { generateDoubleId, getTypedElementId, getTypedId } from 'flyid-core/dist/Util/processFlow';
import { ManualInputField } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';

export const PARENT_NODE_TYPES = [NodeType.LogicalBlock];

export const filterActionTypeNodes = (els: Elements) =>
  els.filter(
    (el): el is Node =>
      (Boolean(el.type) && ManualActionNodeTypes.includes(el.type as NodeType)) ||
      el.type === NodeType.LabelDesign
  );

export function filterNodesByType<T extends SpecificDataTypesListable = undefined>(
  type: NodeType,
  els: Elements
) {
  return els.filter((n): n is TypedNode<T> => n.type === type);
}

export function getNodeById<T extends SpecificDataTypesListable = undefined>(
  nodes: TypedNode[],
  nodeId: string
) {
  const nodesWithId = nodes.filter((n) => n.id === nodeId);
  return nodesWithId.length ? (nodesWithId[0] as unknown as TypedNode<T>) : undefined;
}

export function filterNodesById(nodes: TypedNode[], nodeIds: string[]) {
  return nodes.filter((n) => nodeIds.includes(n.id));
}

export function getSpecificDataById<T extends SpecificDataTypesListable>(nodes: TypedNode<T>[]) {
  const map: MapOf<T> = {};
  nodes
    .map((n) => [getPureId(n.id), cloneDeep(n.data.specificData)])
    .filter((p): p is [string, T] => Boolean(p[1]))
    .forEach(([id, data]) => (map[id] = data));
  return map;
}

export const isNodeTypeCopyable = (type?: NodeType) =>
  !(
    type === NodeType.Start ||
    type === NodeType.End ||
    type === NodeType.LogicalBlock ||
    type === NodeType.Conditional ||
    type === undefined
  );

export const isNodeCopyable = (node: TypedNode) => {
  const type = getType(node.id);
  return isNodeTypeCopyable(type);
};

export function createNodeCopy(
  originalNode: TypedNode,
  getBaseNodeData: (node: FlowNodeFront, isStartEndNode?: boolean) => BaseNodeData,
  onErrorCallback?: OnErrorCallback
) {
  const type = getType(originalNode.id);
  if (!isNodeTypeCopyable(type)) return;

  if (!isNodeDataConsistent(originalNode, getCompletionCheckMethod(type!))) {
    return onErrorCallback?.('processFlow.inconsistentNode', ({ $t }) => ({
      nodeName: $t({ id: `processFlow.${type}` })
    }));
  }

  // Copy original node
  const n = cloneDeep(originalNode);
  // Reposition
  const { x, y } = n.positionAbsolute ?? n.position;
  n.positionAbsolute = {
    x: x + (n.width ?? 160) / 2,
    y: y + (n.height ?? 80) / 2
  };
  n.position = { ...n.positionAbsolute };

  // Free the node
  n.deletable = true;
  n.draggable = true;
  n.selected = false;

  // Remove parent-related stuff, if any
  delete n.parentId;
  delete n.parentNode;
  delete n.data.parent;
  delete n.extent;
  delete n.data.baseNodeData.onDetachFromParent;

  // getBaseNodeData requires id node id to be pure (non-typed).
  n.id = generateDoubleId();
  n.data.baseNodeData = getBaseNodeData({ ...n, type: type! }, false);
  // Now we upgrade to a typed id.
  n.id = getTypedElementId(n.id, type!);

  switch (type) {
    // Nodes with 'name' property
    case NodeType.TakePicture:
    case NodeType.LabelDesign:
    case NodeType.CustomMarker:
      (n.data.specificData as any).name = (originalNode.data.specificData as any).name + ' (Cp)';
      break;
    case NodeType.ManualInputField:
      (n.data.specificData as ManualInputField).field =
        (originalNode.data.specificData as ManualInputField).field + ' (Cp)';
      break;
    // Nodes that don't require any change
    case NodeType.AutoFillData:
    default:
      break;
  }
  return n;
}

export type OnErrorCallback = (
  error: string,
  msgPropsOrFun?:
    | { [param: string]: MessageType }
    | ((intl: IntlShape) => { [param: string]: MessageType })
) => undefined;

export const checkNodeData = <DataType extends SpecificDataTypesListable>(
  nodes: Node<CommonNodeData<SpecificDataTypesListable>>[],
  nodeType: NodeType,
  onErrorCallback?: OnErrorCallback
) => {
  const thisTypeNodes = filterNodesByType<DataType>(nodeType, nodes);
  if (nodeType === NodeType.LabelDesign && !thisTypeNodes.length) {
    return onErrorCallback?.('processFlow.missingLabelDesign');
  }

  for (const node of thisTypeNodes) {
    if (!isNodeDataConsistent<DataType>(node, getCompletionCheckMethod(nodeType))) {
      return onErrorCallback?.('processFlow.inconsistentNode', ({ $t }) => ({
        nodeName: $t({ id: `processFlow.${nodeType}` })
      }));
    }
  }
  return getSpecificDataById(thisTypeNodes);
};

export function getCompletionCheckMethod<DataType>(type: NodeType) {
  let ret;
  switch (type) {
    case NodeType.LabelDesign:
      ret = isLabelDesignComplete;
      break;
    case NodeType.ManualInputField:
      ret = isMifComplete;
      break;
    case NodeType.TakePicture:
      ret = isPictureTakingComplete;
      break;
    case NodeType.AutoFillData:
      ret = isAutoFillDataComplete;
      break;
    case NodeType.Conditional:
      ret = isCaseDataComplete;
      break;
    case NodeType.LogicalBlock:
      ret = isLogicalBlockComplete;
      break;
    case NodeType.CustomMarker:
      ret = isCustomMarkerComplete;
      break;
    default:
      ret = undefined;
  }
  return ret as (d: DataType) => boolean;
}

// Consistency checks
export function isNodeDataConsistent<T extends SpecificDataTypesListable>(
  node: TypedNode<T>,
  checkFunction: Undefinable<(data: T) => boolean>
) {
  const data = node.data.specificData;
  if (!checkFunction) return true;
  if (data) {
    return Array.isArray(data)
      ? (data as T[]).every((el) => checkFunction(el))
      : checkFunction(data);
  }
  return false;
}

export const getNodeSpecificIsValidConnection = (
  sourceNode: TypedNode,
  targetNode: TypedNode,
  conn: Connection,
  flowInstance: AppReactFlowInstance
): Nilable<[result: boolean, intercept: boolean]> => {
  // Check connections by source type
  switch (sourceNode.type) {
    case NodeType.Conditional: {
      // Do not allow chained conditionals, since it is unecessary
      if (targetNode.type === sourceNode.type) return [false, false];

      // Do not allow the same source handle to connect to more than one element,
      // but allow an unconnected single type handle to connect to other nodes
      const outgoingEdges = flowInstance.getEdges().filter((e) => e.source === sourceNode.id);
      return [!isHandleConnected(conn.sourceHandle, 'source', outgoingEdges), true];
    }
    case NodeType.Start: {
      // Allow only one of the following types to be connected to start
      const allowedTypes = [
        NodeType.LabelDesign,
        NodeType.ManualInputField,
        NodeType.TakePicture,
        NodeType.LogicalBlock
      ];

      const target = getType<NodeType>(conn.target);
      if (target) return [allowedTypes.includes(target), false];
      break;
    }
  }

  // Check connections by target type
  switch (targetNode.type) {
    case NodeType.Conditional: {
      // Conditionals inside a parent should not accept any connections,
      // since they are created automatically
      if (targetNode.data.parent) return [false, false];
      break;
    }
  }

  return [true, false];
};

export const getTypedNodes = (reactFlow: Nilable<AppReactFlowInstance>) =>
  reactFlow?.getNodes() ?? [];

// Returns the given node detached from parent
export const getDetachedNode = <T extends SpecificDataTypesListable>(
  parent: TypedNode<T>,
  child: TypedNode
): TypedNode => {
  return {
    ...child,
    // ReactFlow data
    extent: undefined,
    parentNode: undefined,
    position: {
      // Translate node slightly out of parent
      x: parent.position.x + (parent.width ?? child.position.x) - (child.width ?? 100),
      y: parent.position.y + (parent.height ?? child.position.y) - (child.height ?? 30)
    },
    draggable: true,
    deletable: true,
    // Fly.id node data
    data: {
      ...child.data,
      baseNodeData: { ...child.data.baseNodeData, onDetachFromParent: undefined },
      intersectsWith: undefined,
      parent: undefined
    }
  };
};

// Memoization of nodes
type FlowMemoStateType<T extends SpecificDataTypesListable = SpecificDataTypesListable> = Readonly<
  React.PropsWithChildren<ProcessNodeType<T>>
>;
export type FlowMemoIsPropEqual<T extends SpecificDataTypesListable = SpecificDataTypesListable> = (
  prev: FlowMemoStateType<T>,
  next: FlowMemoStateType<T>
) => boolean;

export const isSpecificDataEqual = <
  T extends SpecificDataTypesListable = SpecificDataTypesListable
>(
  prev: FlowMemoStateType<T>,
  next: FlowMemoStateType<T>
): boolean => {
  const oldData = prev.data.specificData;
  const newData = next.data.specificData;
  const _isSpecificDataEqual = !oldData || !newData || oldData === newData;
  // console.log(`isSpecificDataEqual: ${isSpecificDataEqual}`);
  return _isSpecificDataEqual;
};

export const isCommonNodeDataEqual = <
  T extends SpecificDataTypesListable = SpecificDataTypesListable
>(
  prev: FlowMemoStateType<T>,
  next: FlowMemoStateType<T>
): boolean => {
  const _isCommonNodeDataEqual = prev.data === next.data;
  // console.log(`isCommonNodeDataEqual: ${isCommonNodeDataEqual}`);
  return _isCommonNodeDataEqual;
};

export const isBaseNodeDataEqual = <
  T extends SpecificDataTypesListable = SpecificDataTypesListable
>(
  prev: FlowMemoStateType<T>,
  next: FlowMemoStateType<T>
): boolean => {
  const prevData = prev.data.baseNodeData;
  const nextData = next.data.baseNodeData;
  const isEqual = !(
    prevData &&
    nextData &&
    (prevData !== nextData ||
      prevData.isValidConnection !== nextData.isValidConnection ||
      prevData.onCloseClick !== nextData.onCloseClick ||
      prevData.onDetachFromParent !== nextData.onDetachFromParent ||
      prevData.onNodeCopy !== nextData.onNodeCopy ||
      prevData.onEditClick !== nextData.onEditClick)
  );
  // console.log(`isBaseNodeDataEqual: ${isEqual}`);
  return isEqual;
};

export function arePropsEqual<T extends SpecificDataTypesListable = SpecificDataTypesListable>(
  ...funs: FlowMemoIsPropEqual<T>[]
): FlowMemoIsPropEqual<T> {
  return (prev: FlowMemoStateType<T>, next: FlowMemoStateType<T>): boolean => {
    const res = funs.every((f) => f(prev, next));
    // console.log(res);
    return res;
  };
}
