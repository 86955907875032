import { CaseData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import { filterObject } from 'flyid-core/dist/Util/helpers';
import { convertMapToList, MapOf } from 'flyid-core/dist/Util/types';
import { pickBy } from 'lodash';
import { useMemo } from 'react';
import { Nilable } from 'tsdef';
import { AppState } from '../store';
import { selectTargetCompany } from './globalSelectors';

export const selectSettings = (state: AppState, domain: string) => {
  const targetCompany = selectTargetCompany(state);
  const { isLoaded, data } = state.firestore.domainSettings[targetCompany ?? ''] ?? {};
  return isLoaded && targetCompany ? data?.[domain] : null;
};

export const selectSettingsForProcessFlow = (state: AppState, domain: string) => {
  const targetCompany = selectTargetCompany(state);
  // Load state is not useful here, since it represents all target company settings load states
  const { data: companySett } = state.firestore.domainSettings[targetCompany ?? ''] ?? {};

  const originalSett = companySett?.[domain];
  // Convert database maps representing nested lists back to nested lists
  return useMemo(() => {
    if (!targetCompany || !originalSett) return null;

    const conditionals = {} as MapOf<CaseData[]>;
    Object.entries(originalSett.processFlow.conditionals as MapOf<MapOf<CaseData>>).forEach(
      ([id, map]) => (conditionals[id] = convertMapToList(map))
    );

    return {
      ...originalSett,
      processFlow: {
        ...originalSett.processFlow,
        conditionals
      }
    };
  }, [originalSett, targetCompany]);
};

export const selectLicenses = (state: AppState, domain?: string) => {
  const targetCompany = selectTargetCompany(state);
  const { isLoaded, data: authLicenses } = state.firestore.authLicenses[targetCompany ?? ''] ?? {};

  return useMemo(() => {
    if (!isLoaded || !targetCompany) return null;
    return domain ? pickBy(authLicenses, (lic) => lic?.authDomains.includes(domain)) : authLicenses;
  }, [targetCompany, authLicenses]);
};

export const selectApiKeys = (state: AppState, company?: string) => {
  const targetCompany = company ?? selectTargetCompany(state);
  const { isLoaded, data: apiKeys } = state.firestore.apiKeys[targetCompany ?? ''] ?? {};

  return useMemo(
    () =>
      isLoaded && targetCompany
        ? filterObject(apiKeys!, (apiKey) => apiKey?.company === targetCompany)
        : null,
    [targetCompany, apiKeys]
  );
};

export const labelImageSelector = (domain: Nilable<string>, labelId: string, state: AppState) =>
  domainLabelImagesSelector(domain, state)?.[labelId] || undefined;

export const domainLabelImagesSelector = (domain: Nilable<string>, state: AppState) =>
  (domain && state.labelImages[domain]) || undefined;

export const selectCompaniesData = (state: AppState) => state.firestore.companiesData;

export const selectCompanyExhibitionName = (company: string | undefined, state: AppState) => {
  if (!company) return undefined;

  const { isLoaded, data } = selectCompaniesData(state)?.[company] ?? {};
  return isLoaded && data ? data?.exhibitionName ?? company : company;
};
